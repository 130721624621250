import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Button, Badge, Row, Col } from "reactstrap";
import { getOrders } from "services/orders.service";
import * as moment from "moment";
import { getStatusBgClass } from "./common";
import * as _ from "lodash";
import "./list.scss";
import MomentTimezone from "moment-timezone";

export const OrderList = ({
  handleActiveOrder,
  handleScroll,
  displayOrders,
  loadMore,
}) => {
  const [ordersList, setOrdersList] = useState([]);
  const containerRef = useRef(null);
  const [activeCardId, setActiveCardId] = useState();

  useEffect(() => {
    setOrdersList(displayOrders);
  }, [displayOrders]);

  const handleOrder = order => {
    handleActiveOrder(order);
  };

  const handleScrollEvent = () => {
    const container = containerRef.current;

    if (
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 1 &&
      loadMore
    ) {
      handleScroll();
    }
  };

  const handleActiveCard = id => {
    setActiveCardId(id);
  };

  const RenderOrderCards = orders => {
    if (orders?.length === 0) {
      return (
        <CardBody role="button" className="">
          Orders are empty
        </CardBody>
      );
    }

    return (
      <>
        {orders?.map((order, indx) => {
          let pendingStatus =
            order?.orderConfirmedByStylist === false &&
            order?.notAvailable?.notAvailable == false;
          let stylistnotavailable = order?.notAvailable?.notAvailable == true;
          let createdAt = MomentTimezone.tz(
            order.createdAt,
            order.timeZone || "Asia/Calcutta"
          ).format("DD-MMMM-YYYY @ HH:mm A");
          console.log(order, "order");
          const FixedClass = "border border-bottom-1 order_card ";
          const activeStatusClass =
            activeCardId === order?._id
              ? FixedClass + "bg-lightGrey"
              : FixedClass;
          return (
            <CardBody
              role="button"
              key={indx}
              className={activeStatusClass}
              onClick={() => {
                handleOrder(order);
                handleActiveCard(order?._id);
              }}
            >
              <div className="d-flex justify-content-between buttons ">
                <div className="">
                  <h6 className="font-size-18 fw-bold">#{order?.orderId}</h6>
                  <span className="text-secondary ">Order Date: </span>
                  <br />
                  <span className="fw-bold font-size-14">{createdAt}</span>
                  <div className="mt-1">
                    <span className="text-secondary">Customer: </span>
                    <span className="fw-bold">
                      {order?.userid?.firstname ? order.userid.firstname : ""}{" "}
                      {order?.userid?.lastname ? order.userid.lastname : ""}
                    </span>
                  </div>
                </div>
                <div className="text-secondary ms-2">
                  <div className="">
                    <Badge
                      className={
                        " badge rounded border border-0 font-size-12 float-end p-1 px-2 " +
                        getStatusBgClass(
                          " bg",
                          order?.status === "Accept" && pendingStatus
                            ? "Pending"
                            : stylistnotavailable
                            ? "Not available"
                            : order?.status
                        )
                      }
                      pill
                    >
                      {order?.status === "Accept" && pendingStatus
                        ? "Pending"
                        : order?.status === "cancel"
                        ? "Cancelled"
                        : stylistnotavailable
                        ? "Not available"
                        : order?.status}
                    </Badge>
                  </div>
                  <small className="float-end mt-1">
                    {moment(order?.updatedAt).fromNow()}
                  </small>
                </div>
              </div>
            </CardBody>
          );
        })}
      </>
    );
  };

  return (
    <React.Fragment>
      <CardBody className="p-0">
        <div className="tabs Orders_tabs">
          <div
            className="order-scroll"
            ref={containerRef}
            onScroll={handleScrollEvent}
          >
            {RenderOrderCards(ordersList)}
          </div>
        </div>
      </CardBody>
    </React.Fragment>
  );
};
