import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import MomentTimezone from "moment-timezone";
import ProfileImage from "components/profileImage";
import noImage from "assets/images/PicUpload.jpg";
import OrderDetailsTable from "./orderDetailsTable";
import Instructions from "./Instructions";
import Rating from "pages/Orders/Rating";
import { getRatingsByOrderId } from "services/rating.service";
import ServiceOrderHistory from "./OrderHistory";
import AdminNotesServiceOrders from "../../pages/Orders/AdminNotes.jsx";
import ServiceOrderConfirmation from "./ServiceOrderConfirmation";
import SlotsArray from "utils/Slots";
import { getStatusByBgClass } from "components/Common/GetStatus";
const OrdersDetailspage = ({ activeOrder, reload, getallOrders }) => {
  const [order, setOrder] = useState({});
  const [otp, setOtp] = useState([]);
  let [rating, setRating] = useState(null);
  const [totalAmount, setTotalAmount] = useState();

  console.log(order, "order", rating);
  useEffect(() => {
    if (activeOrder && activeOrder.length) {
      const currentOrder = { ...activeOrder[0] };
      currentOrder.createdAt = MomentTimezone.tz(
        currentOrder.createdDate,
        currentOrder?.timeZone || "Asia/Kolkata"
      ).format("DD-MMMM-YYYY @ h:mm a");
      currentOrder.appointmentDate = MomentTimezone.tz(
        currentOrder.appointmentDate,
        currentOrder?.timeZone || "Asia/Kolkata"
      ).format("DD-MMMM-YYYY");
      setOrder(currentOrder);
      getOtpArray(currentOrder?.otp);
      let totalAmount = 0;
      currentOrder?.selectedList?.forEach(service => {
        service?.status === "Complete" ? (totalAmount += service.amount) : 0;
      });
      setTotalAmount(totalAmount?.toFixed(2));
      setRating(currentOrder?.feedback);
    }
  }, [activeOrder]);

  let stylistnotavailable = order?.notAvailable == true;

  const getOtpArray = otp => {
    let otpstring = otp?.toString();
    let otpArray = otpstring ? otpstring?.split("") : "";
    setOtp(otpArray);
  };

  const getSlotTimes = selectedList => {
    let stylistMap = new Map();

    selectedList.forEach(item => {
      const slotNumber = item?.slots[0]?.toString();
      const slotExists = Object.keys(SlotsArray).includes(slotNumber);
      const stylistKey = item.providerid.mobileNumber;

      let existingStylist = stylistMap.get(stylistKey);

      if (existingStylist) {
        if (!existingStylist.slots.includes(slotNumber)) {
          existingStylist.slots.push(slotNumber);
          if (slotExists) {
            existingStylist.slotTimes.push(SlotsArray[slotNumber]);
          } else {
            existingStylist.slotTimes.push(null);
          }
        }
      } else {
        stylistMap.set(stylistKey, {
          stylistName: item.providerid.legalname,
          stylistShortName: item.providerid.shortname,
          stylistMobile: item.providerid.mobileNumber,
          stylistImage: item.providerid.image,
          slots: [slotNumber],
          slotTimes: [slotExists ? SlotsArray[slotNumber] : null],
        });
      }
    });

    return Array.from(stylistMap.values());
  };

  const renderAddress = () => {
    if (typeof order?.address === "string") {
      let address, area, landmark, areaDetails;
      const checkAddress = order?.address?.includes("||");
      if (checkAddress) {
        address = order.address.split("||");
        area = address[1] && address[3] ? address[1] + "," + address[3] : "";
        areaDetails = address[1] ? address[1] : "";
        landmark = address[2] ? address[2] : "";
      }
      return (
        <>
          <div>
            {checkAddress ? (
              <>
                <h6 className="">{address[0]}</h6>
                <h6 className="">{area}</h6>
                <h6 className="">
                  {landmark ? <>Landmark :{landmark}</> : ""}
                </h6>
              </>
            ) : (
              <h6 className="">{order?.address?.replaceAll("|", " ")}</h6>
            )}
          </div>
        </>
      );
    } else if (typeof order?.address === "object") {
      return (
        <>
          <div>
            <>
              <h6 className="">{order?.address?.displayAddress}</h6>
              {/* <h6 className="">{area}</h6>
                <h6 className="">
                  {landmark ? <>Landmark :{landmark}</> : ""}
                </h6> */}
            </>
          </div>
        </>
      );
    }
  };

  const RenderPaymentDone = order => {
    return (
      <div className="d-flex justify-content-between border border-2 rounded p-2 ">
        <div className="d-flex">
          <i className="bx bx-md bx-check-circle text-success p-2"></i>
          {order?.status == "Paid" ? (
            <h6 className="p-2 mt-2">Payment is done</h6>
          ) : (
            <h6 className="p-2 mt-2">Payment is not yet done.</h6>
          )}
        </div>
        {/* {order?.status == "Compelete" || order?.status == "Paid" ? (
          <div>
            <button type="" className="btn btn-primary mt-2 ">
              Refund : ₹ {refundAmt}
            </button>
          </div>
        ) : (
          ""
        )} */}
      </div>
    );
  };

  return (
    <div className="Order_details_card">
      {activeOrder && activeOrder?.length ? (
        <div>
          {" "}
          <Card className="">
            <CardBody className="border-3 border-bottom pb-1">
              <Row>
                <Col md="6" className="">
                  <div className="">
                    <div className="d-flex justify-content-between mb-4 align-items-center">
                      <div className="">
                        <h6 className="font-size-18 fw-bold mb-0">
                          Order ID #{order?.orderId}
                        </h6>
                      </div>
                      {/* {order?.status === "Compelete" ||
                      order?.status === "Paid" ? (
                        <div>
                          <button
                            className={
                              "btn btn-sm font-size-12 text-white " +
                              getStatusByBgClass(
                                " bg",
                                stylistnotavailable
                                  ? "Not available"
                                  : order?.status
                              )
                            }
                          >
                            {stylistnotavailable
                              ? "Not available"
                              : order?.status === "cancel" ||
                                order?.status === "Cancel"
                              ? "Cancelled"
                              : order?.status === "Compelete"
                              ? "Completed"
                              : order?.status}
                          </button>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <h6 className="fw-bold">
                      Created On :
                      <span className="fw-bold"> {order?.createdAt}</span>
                    </h6>
                  </div>
                </Col>
                <Col md="6" className="border-start border-3">
                  {/* {order?.status == "Compelete" || order?.status == "Paid" ? (
                    <div className=" ">
                      <h5 className="">Stylist Earnings</h5>{" "}

                    </div>
                  ) : ( */}
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <p className="mb-2">Appointment Date & Time :</p>
                      <h6 className="">
                        {order?.appointmentDate} @ {order?.appointmentTime}
                      </h6>
                      <div className="d-flex align-items-center">
                        <p className="mb-0 fw-bold font-size-18">OTP :</p>
                        <div className="">
                          {otp?.length ? (
                            <div className="d-flex align-items-center ms-3">
                              {otp?.map((otp, index) => {
                                return (
                                  <div key={index} className="otp-div">
                                    <h6 className="mb-0 otp_num">{otp}</h6>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        className={
                          "btn btn-sm font-size-12 text-white " +
                          getStatusByBgClass(
                            " bg",
                            stylistnotavailable
                              ? "Not available"
                              : order?.status
                          )
                        }
                      >
                        {stylistnotavailable
                          ? "Not available"
                          : order?.status === "cancel" ||
                            order?.status === "Cancel"
                          ? "Cancelled"
                          : order?.status === "Compelete"
                          ? "Completed"
                          : order?.status}
                      </button>
                    </div>
                  </div>
                  {/* )} */}
                </Col>
              </Row>
            </CardBody>
            <CardBody className=" border-3 border-bottom">
              <Row>
                <Col md="12" className="">
                  <div>
                    <div className="bg-light p-1 mb-3">
                      <p className="mb-0">Customer Details</p>
                    </div>
                    <div className="d-flex ">
                      <div className="d-flex me-3">
                        <div>
                          <i className="bx bxs-user-circle me-1 font-size-14"></i>
                        </div>
                        <h6 className="">
                          {order?.user?.firstname ? order.user?.firstname : "-"}{" "}
                          {order?.user?.lastname ? order.user?.lastname : "-"}
                        </h6>
                      </div>

                      <div className="d-flex me-3">
                        <div>
                          <i className="bx bxs-phone-call me-1 font-size-14"></i>
                        </div>
                        <h6 className="">
                          {order?.user?.mobilenumber
                            ? order.user?.mobilenumber
                            : "-"}
                        </h6>
                      </div>
                      <div className="d-flex me-3">
                        <div>
                          <i className="bx bxs-envelope me-1 font-size-14"></i>
                        </div>
                        <h6 className="">
                          {order?.user?.email ? order.user?.email : "-"}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div>
                        <i className="bx bxs-home me-1 font-size-14"></i>
                      </div>
                      {renderAddress()}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="8" className=" border-3 mb-3">
                  <div>
                    <div className="bg-light p-1">
                      <p className="mb-0">Stylist Details</p>
                    </div>
                    {/* {order?.notAvailable?.notAvailable && (
                  <div className="d-flex justify-content-end mb-2">
                    <a
                      className="btn btn-sm btn-primary mt-2 px-3"
                      onClick={() => {
                        getProvidersByZipCode(order);
                      }}
                      style={{
                        backgroundColor: "#ef4354",
                        border: "1px solid #ef4354",
                      }}
                    >
                      Change Stylist
                    </a>
                  </div>
                )} */}
                    <div className="d-flex flex-wrap align-items-center mt-2">
                      {getSlotTimes(order.selectedList || []).map(
                        (stylist, indx) => {
                          console.log(stylist, "stylist");
                          return (
                            <div className="me-4 d-flex flex-wrap" key={indx}>
                              <ProfileImage
                                image={
                                  stylist.stylistImage === "undefined"
                                    ? noImage
                                    : stylist.stylistImage
                                }
                                height="70"
                                width={70}
                                classNames="me-2 border"
                              />
                              <div>
                                <div className="d-flex align-items-center ">
                                  <div>
                                    <i className="bx bxs-user-circle me-1 font-size-14"></i>
                                  </div>
                                  <h6 className="mt-1 ">
                                    {stylist.stylistName || "-"}{" "}
                                    {stylist.stylistShortName || "-"}
                                  </h6>
                                </div>

                                <div className="d-flex align-items-center ">
                                  <div>
                                    <i className="bx bxs-phone-call me-1 font-size-14"></i>
                                  </div>
                                  <h6 className="mt-1">
                                    {stylist.stylistMobile
                                      ? stylist.stylistMobile
                                      : "-"}
                                  </h6>
                                </div>

                                <div className="d-flex">
                                  <div>
                                    <i className="bx bxs-time me-1 font-size-14"></i>
                                  </div>
                                  <h6 className="">
                                    Service date & Time: <br />
                                    {stylist.slotTimes.map((slot, index) => (
                                      <h6 key={index} className="mt-1">
                                        {order?.appointmentDate} @ {slot}
                                        {index !== stylist.slots.length - 1 &&
                                          ", "}
                                      </h6>
                                    ))}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  {!stylistnotavailable && (
                    <div className="mb-2 border-start ps-2">
                      <ServiceOrderConfirmation
                        activeOrder={order}
                        getOrdersList={getallOrders}
                        getOrderById={reload}
                        Status={order?.status}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
            <CardBody className="border-3 border-bottom">
              <OrderDetailsTable
                serviceList={order.selectedList ? order.selectedList : []}
                activeOrder={order}
                getallOrders={getallOrders}
                getOrderById={reload}
              />
              {order?.status == "cancel" || order?.status === "Cancel" ? (
                <div className="mt-3">
                  <div className="bg-light p-2">
                    <h6>Reason for cancellation</h6>
                  </div>
                  {/* <p className="p-2">{activeOrder?.CancelReason}</p> */}
                </div>
              ) : (
                ""
              )}
            </CardBody>
          </Card>
          <Card>
            {/* <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="p-2 mt-2 ms-2">Order Summary</h5>
              </div>

              <div className="px-3">
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm px-2"
                  // onClick={() => Bonustoggle()}
                >
                  Add Bonus
                </Button>
              </div>
            </div> */}
            <CardBody className="border-top border-3 py-0 px-3">
              {" "}
              <div className="d-flex justify-content-start mb-0 mt-4 px-0">
                <div className="me-5">
                  {order?.status == "Paid" ? (
                    <p className="">Payment Method </p>
                  ) : (
                    ""
                  )}
                  <p className="">Services Amount</p>
                  {/* <p className="">Products</p> */}
                  {order?.orderPaymentInfo?.additionalServiceAmount ? (
                    <p className="text-danger">Additional Charges</p>
                  ) : (
                    ""
                  )}
                  {/* {activeOrder?.biddings[0]?.tipAmount ? (
                <p className="text-success fw-bold">Tip amount</p>
              ) : (
                ""
              )} */}
                  {/* <p className="">Taxes</p>
              {activeOrder?.notAvailable?.bonusToStylist ? (
                <p className="text-danger">Bonus To Stylist</p>
              ) : (
                ""
              )} */}
                  {/* {activeOrder?.discount ? (
                <p className="text-success">Discount</p>
              ) : (
                ""
              )} */}
                  {/* <p className="">Partner Amount</p> */}
                  <p className="">
                    <b>Grand Total</b>
                  </p>
                </div>

                <div className="text-end ms-5">
                  {order?.status == "Paid" ? (
                    <p className="">{order?.orderPaymentInfo?.paymentType} </p>
                  ) : (
                    ""
                  )}
                  <p className="">
                    ₹ {order?.orderPaymentInfo?.serviceAmount?.toFixed(2)}
                  </p>
                  {/* <p className="">₹ {0}</p> */}
                  {order?.orderPaymentInfo?.additionalServiceAmount ? (
                    <p className="">
                      ₹{" "}
                      {order?.orderPaymentInfo?.additionalServiceAmount?.toFixed(
                        2
                      )}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* <p className="">₹ {0}</p> */}

                  <p className="">₹ {totalAmount}</p>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            {" "}
            <CardBody className="p-2">
              {" "}
              {order?.status != "cancel" || order?.status != "Cancel"
                ? RenderPaymentDone(order)
                : ""}
            </CardBody>
          </Card>
          <Card>
            {" "}
            <CardBody className="p-2">
              <Instructions
                Instructions={
                  order?.stylistInstructions ? order?.stylistInstructions : ""
                }
              />
            </CardBody>
          </Card>
          {rating ? (
            <Rating value={rating} />
          ) : (
            <div>
              <Card>
                <h5 className="p-2 mt-2 ms-2">Rating & Review</h5>
                <CardBody className="border-top border-3">
                  There are no reviews
                </CardBody>
              </Card>
            </div>
          )}
          <ServiceOrderHistory OrderHistory={order?.orderHistory} />
          <div className="border-top border-3">
            <AdminNotesServiceOrders activeOrder={order} />
          </div>
        </div>
      ) : (
        <Card className="p-3 pt-0 empty_order">
          <CardBody className="border-3">
            <div className="text-center">
              <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
            </div>
            <p className="text-center">Please Select Order</p>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default OrdersDetailspage;
