import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="">
                <i className="bx bx-cog "></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/zipcode">{props.t("Zip Code Enabler")}</Link>
                </li>
                <li>
                  <Link to="/app-settings">{props.t("App Settings")}</Link>
                </li>
                <li>
                  <Link to="/slide-list">{props.t("Slides")}</Link>
                </li>
                <li>
                  <Link to="/page-list">{props.t("Pages")}</Link>
                </li>
                <li>
                  <Link to="/avatar-list">{props.t("Avatars")}</Link>
                </li>
                <li>
                  <Link to="/payments-list">{props.t("Payment Methods")}</Link>
                </li>
              </ul>
            </li>
            <li className="menu-title">{props.t("Company Order Data")}</li>
            <li>
              <Link to="/Order_details" className="">
                <i className="bx bx-detail"></i>
                <span> {props.t("Statements")}</span>
              </Link>
            </li>
            <li>
              <Link to="/orders" className="">
                <i className="bx bx-receipt"></i>
                <span> {props.t("Orders")}</span>
              </Link>
            </li>
            <li>
              <Link to="/ecom-orders" className="">
                <i className="bx bx-basket"></i>
                <span> {props.t("E-Orders")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Artist-Forms" className="">
                <i className="bx bx-paint"></i>
                <span> {props.t("Makeup Quote/Orders")}</span>
              </Link>
            </li>
            <li>
              <Link to="/users2" className="">
                <i className="bx bx-user-plus"></i>
                <span> {props.t("Customer Data")}</span>
              </Link>
            </li>
            <li>
              <Link to="/contact-us-users" className=" ">
                <i className="bx bx-user"></i>
                <span>{props.t("Contact-Us Queries")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Provider Information")}</li>
            <li>
              <Link to="/Provider-list-individul" className=" ">
                <i className="bx bx-user"></i>
                <span>{props.t("Individual")}</span>
              </Link>
            </li>

            <li>
              <Link to="/Provider-list-company" className=" ">
                <i className="bx bx-building-house"></i>
                <span>{props.t("Company")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Provider-list-artist" className=" ">
                <i className="bx bx-paint"></i>
                <span>{props.t("Artist")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Service Categories")}</li>
            <li>
              <Link to="/parentcategory-new" className=" ">
                <i className="bx bx-notepad"></i>
                <span>{props.t("Parent Category")}</span>
              </Link>
            </li>
            <li>
              <Link to="/SubCategorieNew" className=" ">
                <i className="bx bx-spreadsheet"></i>
                <span>{props.t("Sub Category")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Services-list" className=" ">
                <i className="bx bx-purchase-tag-alt"></i>
                <span>{props.t("Services")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/Artist-services-list" className=" ">
                <i className="bx bx-purchase-tag"></i>
                <span>{props.t("Artist Services")}</span>
              </Link>
            </li> */}

            <li className="menu-title">{props.t("Ecommerce Categories")}</li>
            <li>
              <Link to="/list-brand" className="">
                <i className="bx bx-gift"></i>
                <span> {props.t("E-Brands")}</span>
              </Link>
            </li>
            <li>
              <Link to="/list-attribute" className="">
                <i className="bx bx-list-plus"></i>
                <span> {props.t("E-Variants")}</span>
              </Link>
            </li>
            <li>
              <Link to="/products-list" className="">
                <i className="bx bx-basket"></i>
                <span> {props.t("E-Products")}</span>
              </Link>
            </li>
            <li>
              <Link to="/products-category-list" className="">
                <i className="bx bx-receipt"></i>
                <span> {props.t("E-Product Categories")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Management")}</li>
            <li>
              <Link to="/articles" className="">
                <i className="bx bxs-book-content"></i>
                <span> {props.t("Articles")}</span>
              </Link>
            </li>
            <li>
              <Link to="/Coupon-mangement" className="">
                <i className="bx bxs-coupon"></i>
                <span>{props.t("Coupons")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Mobile Configue")}</li>
            <li>
              <Link to="/labels" className="">
                <i className="bx bx-image"></i>
                <span> {props.t("Mobile HomePage")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Service reviews")}</li>
            <li>
              <Link to="/ratings-questions" className="">
                <i className="bx bx-help-circle"></i>
                <span> {props.t("Service Questions")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Orders Settlements")}</li>
            <li>
              <Link to="/settlements-summary-individual" className="">
                <i className="bx bx-user"></i>
                <span> {props.t("Individual")}</span>
              </Link>
            </li>
            <li>
              <Link to="/settlements-summary-company" className="">
                <i className="bx bx-buildings"></i>
                <span> {props.t("Company")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("App Exceptions")}</li>
            <li>
              <Link to="/exceptions" className="">
                <i className="bx bx-error"></i>
                <span> {props.t("Exceptions")}</span>
              </Link>
            </li>

            {/* <li className="menu-title">{props.t("Authors")}</li>
            <li>
              <Link to="/Authors" className="">
                <i className="bx bx-user"></i>
                <span> {props.t("Authors")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
{
  {
    /* <li>
              <Link to="/#" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
      
            </li> */
  }
  /* <li>
                  <Link to="/parentcategory-list">
                    {props.t("Parent Category")}
                  </Link>
                </li> */
  {
    /* <li>
              <Link to="/provider-management" className=" ">
                <i className="bx bx-calendar"></i>
                <span>{props.t("Provider Management")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/packages-list">
                <i className="bx bx-archive"></i>
                {props.t("Packages")}
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/packages-list" className="">
                <i className="bx bx-archive"></i>
                <span>{props.t("Packages")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/orders" className="">
                <i className="bx bx-receipt"></i>
                <span> {props.t("Orders")}</span>
              </Link>
            </li> */
  }

  {
    /* */
  }
  {
    /* <li>
              <Link to="/mobile-login" className="">
                <i className="bx bx-receipt"></i>
                <span> {props.t("Login")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/order2" className="">
                <i className="bx bx-receipt"></i>
                <span> {props.t("Orders2")}</span>
              </Link>
            </li> */
  }
  {
    /* <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Provider-list-company">{props.t("company")}</Link>
                </li>
                <li>
                  <Link to="/FileMan">{props.t("New Service")}</Link>
                </li>
                <li>
                  <Link to="/service-cost">{props.t("service-cost1")}</Link>
                </li>
              </ul> */
  }
  {
    /* <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Provider-list-individul">
                    {props.t("Individual")}
                  </Link>
                </li>
              </ul> */
  }
  {
    /* <li>
              <Link to="/settingsView" className="">
                <i className="bx bx-cog "></i>
                <span>{props.t("Settings New")}</span>
              </Link>
            </li> */
  }
  {
    /* <li>
              <Link to="/#">
                <i className="bx bx-group"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/User-list">{props.t("Users List")}</Link>
                </li>
              </ul>
            </li> */
  }
  {
    /* <li>
                  <Link to="/global-settings" >
                    {props.t("Global Settings")}
                  </Link>

                </li> */
  }
  {
    /* <li>
                  <Link to="/Services-list">{props.t("Services")}</Link>
                </li> */
  }
  {
    /* <li>
                  <Link to="/quick-services-list">
                    {props.t("Quick Services")}
                  </Link>
                </li> */
  }

  {
    /* <li>
                  <Link to="/Services-create">
                    {props.t("Create Services")}
                  </Link>
                </li> */
  }
  {
    /* <li>
                  <Link to="/brands">{props.t("Brands")}</Link>
                </li> */
  }

  {
    /* <li><Link to="/brand-create">
                  {props.t("Create Brand")}
                </Link>
                </li> */
  }
  {
    /* <li>
                  <Link to="/parentcategory-create">
                    {props.t("Create Parent Category")}
                  </Link>
                </li> */
  }
  {
    /* <li>
                  <Link to="/category-list">{props.t("Sub Category")}</Link>
                </li> */
  }
  {
    /* <li> <Link to="/category-create">
                  {props.t("Sub Create Category")}
                </Link>
                </li> */
  }
  {
    /* <li>
                  {" "}
                  <Link to="/products">{props.t("Products")}</Link>
                </li> */
  }
  {
    /* <li><Link to="/product-create">
                  {props.t("Create Product")}
                </Link></li> */
  }
  {
    /* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-user-check"></i>
                <span>{props.t("providers")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/provider-type">{props.t("Provider Type")}</Link>
                </li>
                <li>
                  <Link to="/provider-list">{props.t("Provider")}</Link>
                </li>
                <li>
                  <Link to="/gallery-list">{props.t("Gallery-list")}</Link>
                </li>
              </ul>
            </li> */
  }
  {
    /* <li>
              <Link to="/provider-management" className=" ">
                <i className="bx bx-calendar"></i>
                <span>{props.t("Provider Management")}</span>
              </Link>
            </li> */
  }
}
